// Services
import callAPI from "../callApi";
import path from "../variables";

const Sync = {
	getSyncStatus: () => callAPI("GET", `${path.base}/sync/info`, "", "getting sync status"),
	verifDataToSync: () =>
		callAPI(
			"GET",
			`${path.base}/sync/available-data-to-sync`,
			"",
			"getting available data to sync"
		),
	syncUp: () => callAPI("GET", `${path.base}/sync/up`, "", "starrting sync operation"),
};

export default Sync;
